import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=06e34afa&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VacatureBar: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/vacature/Bar.vue').default,IconsActionsEdit: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/actions/Edit.vue').default,IconsActionsArrow: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/actions/Arrow.vue').default})
