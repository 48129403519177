
import vacatures from '~/graphql/queries/vacatures.gql';
import specialismen from '~/graphql/queries/specialismen.gql';
import page from '~/plugins/mixins/page';
import auth from '~/plugins/auth';

export default {
  mixins: [page],
  auth: false,
  apollo: {
    specialismen: {
      query: specialismen,
    },
    vacatures: {
      query: vacatures,
      variables() {
        return {
          limit: 3,
        };
      },
    },
  },
  data: () => ({
    specialismen: [],
    vacatures: {},
    formData: {},
    regioOptions: ['Drenthe', 'Flevoland', 'Friesland', 'Gelderland', 'Groningen', 'Limburg', 'Noord-Brabant', 'Noord-Holland', 'Overijssel', 'Utrecht', 'Zeeland', 'Zuid-Holland'],
  }),
  methods: {
    auth,
    search() {
      this.$router.push(this.localePath({
        name: 'vacatures',
        query: {s: this.formData.specialisme, r: this.formData.regio},
      }));
    },
  },
};
