import { render, staticRenderFns } from "./Bar.vue?vue&type=template&id=3dfb48c0&"
import script from "./Bar.vue?vue&type=script&lang=js&"
export * from "./Bar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsMap: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Map.vue').default,IconsHat: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Hat.vue').default,IconsClock: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Clock.vue').default,IconsBarchart: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Barchart.vue').default,IconsCase: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Case.vue').default})
