import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=387fd3d6&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsActionsArrow: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/actions/Arrow.vue').default})
