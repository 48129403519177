
export default {
  props: {
    vacature: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // Amount of days before the "Verloopt bijna!" label gets shown
    expiresSoonTimeThreshold: 5,
    // Amount of days for which the "Nieuw binnen" label gets shown after creation
    isNewTimeThreshold: 5,
  }),
  computed: {
    logoPath() {
      if (this.vacature.logo.length < 1) {
        return require('~/assets/img/logo.svg');
      }

      return this.vacature.logo[0].url;
    },
    expiresSoon() {
      if (!this.vacature.einddatum) {
        return false;
      }

      const expiryTime = new Date(this.vacature.einddatum.replace(/ /g, 'T')).getTime();
      const now = new Date().getTime();

      // 86400000 ms = 1 day
      return (expiryTime - now) < (this.expiresSoonTimeThreshold * 86400000);
    },
    isNew() {
      const createdAt = new Date(this.vacature.created_at.replace(/ /g, 'T')).getTime();
      const now = new Date().getTime();

      // 86400000 ms = 1 day
      return (now - createdAt) < (this.isNewTimeThreshold * 86400000);
    },
  },
};
