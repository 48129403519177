
export default {
  props: {
    vacature: {
      type: Object,
      default: () => ({
        plaatsnaam: 'Plaats',
        aantal_uur_per_week: 'Aantal',
        werkervaring: 'Werkervaring',
      }),
    },
    detailed: {
      type: Boolean,
      default: () => false,
    },
  },
};
