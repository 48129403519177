import { render, staticRenderFns } from "./index.vue?vue&type=template&id=64fa3582&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=64fa3582&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fa3582",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsHat: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Hat.vue').default,IconsMap: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/Map.vue').default,IconsActionsSearch: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/icons/actions/Search.vue').default,Hero: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/base/Hero.vue').default,VacatureBar: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/vacature/Bar.vue').default,VacatureCard: require('/data/www/vacaturebank.kngf.nl/kngf/nuxt/components/vacature/Card.vue').default})
